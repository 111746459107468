.contentWrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

@media all and (max-width: 1023px) {
  .contentWrapper {
    padding: 0 30px;
  }
}

@media all and (max-width: 767px) {
  .contentWrapper {
    padding: 0 12px;
  }
}
