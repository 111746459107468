.parkingPageBlock {
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 40px 30px 40px 40px;
  box-sizing: border-box;
  border: 1px solid #E6EEF0;
  min-height: 230px;
}

.parkingPageBlock-content {
  max-width: 375px;
}

.parkingPageBlock-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
}

.parkingPageBlock-icon svg,
.parkingPageBlock-icon img {
  width: 100%;
  height: 100%;
}

.parkingPageBlock-title {
  margin-bottom: 10px;
  line-height: 28px;
}

.parkingPageBlock-description {
  font-size: 14px !important;
  line-height: 20px !important;
}

.parkingPageBlock-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-grow: 2;
  margin-top: 20px;
}

@media all and (max-width: 1023px) {
  .parkingPageBlock{
    width: 100%;    
  }
}

@media all and (max-width: 768px) {
  .parkingPageBlock-title {
    font-size: 20px;
    line-height: 24px;
  }

}

@media all and (max-width: 359px) {
  .parkingPageBlock {
    padding: 20px;
    min-height: auto;
  }
  .parkingPageBlock-description {
    font-size: 13px;
    line-height: 16px;
  }
}
