.linkInBtn {
  border: 0 !important;
}

.linkInBtn.primary{
  color: #fff;
}

.linkInBtn.secondary {
  color: #030303;
}

.defaultLink span {
  transition: color .2s ease;
}

.defaultLink:hover span {
  color: #5e696e;
}
