.header {
  flex: 0 0 auto;
  padding: 24px 0 10px 0;
}

.header .logoWrapper {
  width: fit-content;
}

.header .logoWrapper svg {
  margin: 0 !important;
}

/* Импотенты чтобы перетереть стили из ui-icons */
.logo {
  width: 195px !important;
  height: 42px !important;
}

.logoEn {
  width: 217px !important;
  height: 42px !important;
}

@media all and (max-width: 1023px) {
  .header {
    padding: 12px 0;
  }  
}

@media all and (max-width: 767px) {
  .logo {
    width: 130px !important;
    height: 28px !important;
  }

  .logoEn {
    width: 145px !important;
    height: 28px !important;
  }
}

@media all and (max-width: 359px) {
  .header {
    padding: 17px 0 10px 0;
  }
}
