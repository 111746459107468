.htmlContent {
  width: 100%;
  padding-top: 30px;
  text-align: center;
}

.htmlContent h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
}

.htmlContent * {
  margin: 0;
}

@media all and (max-width: 768px) {
  .htmlContent h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
}

@media all and (max-width: 359px) {
  .htmlContent {
    padding-top: 20px;
  }

  .htmlContent h3 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
}
  
