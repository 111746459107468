.linesContentView {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.linesContentViewGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

@media all and (max-width: 1023px) {
  .linesContentViewGrid {
    grid-template-columns: 1fr;
  }
}
