.footer {
  flex: 0 0 auto;
  padding: 30px 0;
  border-top: 1px solid #E6EEF0;
  margin-top: 100px;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
}
  
.footerContentWrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  align-items: center;
}

.footerLang {
  display: flex;
  gap: 3px;
  align-items: center;
  transition: all .2s ease;
}

.footerLang svg {
  transition: all .2s ease;
  width: 20px;
  height: 20px;
  fill: #030303;
}

.footerMenu {
  display: flex;
  max-width: 450px;
  list-style: none;
  column-gap: 24px;
  row-gap: 12px;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  justify-content: flex-end;
}

@media all and (max-width: 767px) {
  .footerContentWrapper {
    flex-direction: column;
  }

  .footerContent {
    order: 2;
    gap: 20px;
    align-items: center;
  }

  .footerMenu {
    margin: 0;
    justify-content: center;
    margin-bottom: 30px;
  }
}

@media all and (max-width: 359px) {
  .footerContentWrapper {
    padding: 0 30px;
  }

  .footerMenuColumn:not(:last-of-type) {
    margin-right: 10px;
  }
}
