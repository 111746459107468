.expiredMainBlock {
  display: flex;
  align-items: center;
  column-gap: 40px;
}

.expiredMainBlock-wrapper {
  padding: 100px 0 80px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.expiredMainBlock-content {
  display: flex;
  flex-direction: column;
  max-width: 616px;
  width: 100%;
  padding-left: 20px;
}

.expiredMainBlock-title {
  margin-bottom: 14px;
}

.expiredMainBlock-title h1 {
  font-size: 50px;
  font-weight: 500;
  line-height: 54px;
}

.expiredMainBlock-description {
  font-size: 18px;
  line-height: 24px;
}

.expiredMainBlock-hint {
  margin-top: 20px;
}

.expiredMainBlock-btn {
  align-self: flex-start;
  margin-top: 50px;
}

.expiredMainBlock-image {
  max-width: 544px;
  width: 50%;
}

@media all and (max-width: 767px) {
  .expiredMainBlock {
    flex-direction: column;
  }

  .expiredMainBlock-wrapper {
    padding: 60px 0 40px;
  }

  .expiredMainBlock-content {
    order: 1;
    max-width: 100%;
    padding-left: 0;
    margin: 0;
  }

  .expiredMainBlock-description {
    font-size: 14px;
    line-height: 22px;
  }

  .expiredMainBlock-image {
    max-width: 580px;
    width: 100%;
    margin-bottom: 40px;
  }

  .expiredMainBlock-title h1 {
    font-size: 37px;
    font-weight: 500;
    line-height: 40px;
  }

  .expiredMainBlock-btn,
  .expiredMainBlock-btn button {
    width: 100%;
  }
}

@media all and (max-width: 359px) {
  .expiredMainBlock-wrapper {
    padding: 50px 0 40px;
  }

  .expiredMainBlock-hint {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .expiredMainBlock-image {
    margin-bottom: 30px;
  }

  .expiredMainBlock-btn {
    margin-top: 40px;
  }

  .expiredMainBlock-title h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 34px;
  }
}
  
